import AES from "crypto-js/aes";
import sha256 from "crypto-js/sha256";
import enc from "crypto-js/enc-utf8"

function YouthCrypto(type, secret, data) {
    try {
        if (type === 'encode') {
            return AES.encrypt(data, secret).toString()
        } else if (type === 'decode') {
            return AES.decrypt(data, secret).toString(enc);
        }
    }
    catch (ex) {
        console.log(ex);
        return false;
    }
}

function Save(name, value) {
    const _name = sha256(name);
    localStorage.setItem(
        _name,
        YouthCrypto('encode', name, value)
    )
}
function Read(name) {
    const _name = sha256(name);
    const value = localStorage.getItem(_name);
    if (!value || value === null) return ''
    return YouthCrypto('decode', name, value);
}
function Remove(name) {
    const _name = sha256(name);
    localStorage.removeItem(_name);
}


export const YouthStorage = {
    Save, Read, Remove
}