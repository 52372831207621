import React, { lazy } from 'react';

const NotFound = lazy(() => import('../pages/auth/Page404'));
const Dashboard = lazy(() => import('../pages/dashboard'));
const I18n = lazy(() => import('../pages/i18n'));
const Modules = lazy(() => import('../pages/i18n/modules'));


const presentation = [
	{
		path: '/:path/:locale',
		element: <Modules />,
		exact: true,
	},
	{
		path: '/:path',
		element: <I18n />,
		exact: true,
	},
	{
		path: '/',
		element: <Dashboard />,
		exact: true,
	},
	{
		path: '*',
		element: <NotFound />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;
