import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { commonMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { RenewToken } from '../utils/renew';
import showNotification from '../components/extras/showNotification';
import { YouthStorage } from '../utils/localStorage';
import { useAuth } from '../contexts/authContext';
import YouthServers from '@youth10313/youth-apis';
import { CustomYouth } from '../components/icon/svg-icons';
import Spinner from '../components/bootstrap/Spinner';
import Login from '../pages/auth/Login';

const App = () => {
	getOS();
	const [authenticating, SetAuthenticating] = useState(true);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	const auth = useAuth();

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".

	useEffect(() => {
		RenewToken(
			state => state && showNotification('Token Renew', 'Don\'t make any request for a minute...', 'info'),
			err => {
				showNotification('Authenticate Failed', 'We cloudn\'t able to renew your token.', 'danger');
				YouthStorage.Remove('private')
				YouthStorage.Remove('Authenticate')
				YouthStorage.Remove('Authorization')
			}
		)
	}, []);
	useEffect(() => {
		const expire = parseInt(localStorage.getItem('expire'));
		const now = new Date().getTime();
		const _private = YouthStorage.Read('private');
		if (auth.state.isAuth) return SetAuthenticating(false);
		if (now < expire || !_private) return SetAuthenticating(false);
		const login = YouthServers.Auth.Login.RenewUserToken(_private);
		login.onChange(() => null);
		login.onError(() => SetAuthenticating(false));
		login.onComplete(res => {
			if (res.msg !== 'TOKEN_SENT') return SetAuthenticating(false);
			localStorage.setItem('expire', (new Date().getTime() + res.time) - (1000 * 60 * 2));
			YouthStorage.Save('Authorization', res.token);
			auth.dispatch({ ...auth.state, isAuth: true });
			SetAuthenticating(false);
		})
		login.subscribe()
	}, []);
	useEffect(() => {
		if (auth.state.isAuth) return SetAuthenticating(false);
	}, [auth.state.isAuth]);
	return (
		<ThemeProvider theme={theme}>
			{
				authenticating ? <div
					className='app d-flex justify-content-center align-items-center'
					style={{
						backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
						zIndex: fullScreenStatus && 1,
						overflow: fullScreenStatus && 'scroll',
					}}>
					<CustomYouth width='10rem' height='10rem' />
					<span className='text-xs p-2'>
						<span className='p-1'>
							<Spinner size={10} />
						</span>
						Authenticating...
					</span>
				</div> :

					<ToastProvider components={{ ToastContainer, Toast }}>
						<TourProvider
							steps={steps}
							styles={styles}
							showNavigation={false}
							showBadge={false}>
							<div
								ref={ref}
								className='app'
								style={{
									backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
									zIndex: fullScreenStatus && 1,
									overflow: fullScreenStatus && 'scroll',
								}}>
								{
									auth.state.isAuth ?
										<><Routes><Route path='*' element={<Aside />} /></Routes><Wrapper /></> :
										<Routes>
											<Route path={'*'} element={<Login />} />
										</Routes>
								}
							</div>
							<Portal id='portal-notification'>
								<ReactNotifications />
							</Portal>
						</TourProvider>
					</ToastProvider>
			}
		</ThemeProvider>
	);
};

export default App;
