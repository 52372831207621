import React from 'react';
import Avatar from '../components/Avatar';
import { STATIC_API } from '../constants/api';
import { useAuth } from '../contexts/authContext';

const CommonHeaderChat = () => {
	const auth = useAuth();
	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				role='presentation'>
				<div className='me-3'>
					<div className='text-end'>
						<div className='fw-bold fs-6 mb-0'>
							{`${auth.state.user?.nickname}`}
						</div>
						<div className='text-muted'>
							<small>{auth.state.user?.customRoleTitle || auth.state.user?.username}</small>
						</div>
					</div>
				</div>
				<div className='position-relative'>
					<Avatar
						srcSet={`${STATIC_API}${auth.state.user.avatar}`}
						src={`${STATIC_API}${auth.state.user.avatar}`}
						size={48}
						color={'info'}
					/>
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				</div>
			</div>
		</>
	);
};

export default CommonHeaderChat;
