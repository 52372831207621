import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).init({
	order: ['path', 'localStorage', 'navigator'],
	lookupLocalStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	caches: ['localStorage'],
	excludeCacheFor: ['cimode']
});
i18n.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en-US',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
