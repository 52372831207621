import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import AuthProvider from './contexts/authContext';
ReactDOM.render(
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<AuthProvider>
					<App />
				</AuthProvider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);
reportWebVitals();
