
export const commonMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	Club: {
		id: 'club',
		text: 'Club',
		path: '/club',
		icon: 'GTranslate',
		subMenu: null,
	},
	DivineClub: {
		id: 'divineclub',
		text: 'Divine Justice Club',
		path: '/divineclub',
		icon: 'GTranslate',
		subMenu: null,
	},
	Mobile: {
		id: 'mobile',
		text: 'Mobile App',
		path: '/mobile',
		icon: 'GTranslate',
		subMenu: null,
	},
	Main: {
		id: 'main',
		text: 'Main Site',
		path: '/main',
		icon: 'GTranslate',
		subMenu: null,
	},
	Reasons: {
		id: 'reasons',
		text: 'Reasons Site',
		path: '/reasons',
		icon: 'GTranslate',
		subMenu: null,
	},
	FAQ: {
		id: 'faq',
		text: 'FAQ Site',
		path: '/faq',
		icon: 'GTranslate',
		subMenu: null,
	},
	Library: {
		id: 'library',
		text: 'Library Site',
		path: '/library',
		icon: 'GTranslate',
		subMenu: null,
	},
	Blog: {
		id: 'blog',
		text: 'Blog Site',
		path: '/blog',
		icon: 'GTranslate',
		subMenu: null,
	},
	Bigbang: {
		id: 'bigbang',
		text: 'Bigbang Site',
		path: '/bigbang',
		icon: 'GTranslate',
		subMenu: null,
	},
	Islam: {
		id: 'islam',
		text: 'Islam Site',
		path: '/islam',
		icon: 'GTranslate',
		subMenu: null,
	},
	School: {
		id: 'school',
		text: 'School Site',
		path: '/school',
		icon: 'GTranslate',
		subMenu: null,
	},
	Music: {
		id: 'music',
		text: 'Music Site',
		path: '/music',
		icon: 'GTranslate',
		subMenu: null,
	},
	OfficialAPI: {
		id: 'officialapi',
		text: 'OfficialAPI',
		path: '/officialapi',
		icon: 'GTranslate',
		subMenu: null,
	},
	CallOfMahdi: {
		id: 'call-of-mahdi',
		text: 'CallOfMahdi',
		path: '/call-of-mahdi',
		icon: 'GTranslate',
		subMenu: null,
	},
};
