import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Logo from '../../components/Logo';
import useDarkMode from '../../hooks/useDarkMode';
import { onChangeProvider } from '../../utils/onChange';
import showNotification from '../../components/extras/showNotification';
import Spinner from '../../components/bootstrap/Spinner';
import YouthAPIs from "@youth10313/youth-apis";
import { YouthStorage } from '../../utils/localStorage';
import { useAuth } from '../../contexts/authContext';
import { CustomYouth } from '../../components/icon/svg-icons';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { dispatch } = useAuth()
	const { darkModeStatus } = useDarkMode();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		email: '',
		password: '',
		code: ''
	})
	const [usernameInput, setUsernameInput] = useState(false);
	const [trueUser, setTrueUser] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const LoginAPI = YouthAPIs.Auth.Login.LoginUserStepOne(form)
	LoginAPI.onChange(setLoading);
	LoginAPI.onError(err => showNotification('Server Error', 'We have an error', 'danger'));
	LoginAPI.onComplete(res => {
		setTrueUser(true);
	})
	const Login2API = YouthAPIs.Auth.Login.LoginUserStepTwo(form)
	Login2API.onChange(setLoading);
	Login2API.onError(err => showNotification('Server Error', 'We have an error', 'danger'));
	Login2API.onComplete(res => {
		if (res.msg === 'LOGGED_IN_SUCCESSFULLY') {
			if (res.profile.role.split(":")[0] !== '13' && res.profile.role.split(":")[0] !== '313') return showNotification('Access Denied', 'You Cannot Access This App.', 'danger');
			YouthStorage.Save('Authorization', res.token)
			YouthStorage.Save('private', res.privateKey)
			YouthStorage.Save('profile', JSON.stringify(res.profile))
			localStorage.setItem('expire', new Date().getTime() + (1000 * 60 * 28))
			handleOnClick();
			showNotification('Logged in', 'You\'re logged in succefully.', 'success');
			dispatch({
				user: res.profile,
				isAuth: true
			})
		}
	})
	function login() {
		if (loading) return;
		if (trueUser && !form.code) return;
		if (trueUser) return Login2API.subscribe();
		LoginAPI.subscribe();
	}

	const onChange = onChangeProvider(form, setForm);
	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<CustomYouth height={100} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									{isNewUser ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												{!trueUser ? <span>{!usernameInput ? (
													<FormGroup
														id='login-username'
														isFloating
														label='Your email or username'>
														<Input autoComplete='username' value={form.email} onChange={onChange('email')} />
													</FormGroup>
												) : (
													<FormGroup
														id='login-password'
														isFloating
														label='Password'>
														<Input
															type='password'
															autoComplete='password'
															value={form.password}
															onChange={onChange('password')}
														/>
													</FormGroup>
												)}</span> : (
													<FormGroup
														id='login-code'
														isFloating
														label='Code'>
														<Input
															type='text'
															autoComplete='code'
															value={form.code}
															onChange={onChange('code')}
														/>
													</FormGroup>
												)
												}
											</div>
											<div className='col-12'>
												{
													!trueUser && <span>
														{!usernameInput ? (
															<Button
																color='warning'
																className='w-100 py-3'
																onClick={() => form.email && setUsernameInput(true)}>
																Continue
															</Button>
														) : (
															loading ? <div className='text-center'><Spinner /></div> : <Button
																color='warning'
																className='w-100 py-3'
																onClick={() => form.password && login()}>
																Login
															</Button>
														)}
													</span>
												}
												{trueUser && (loading ? <div className='text-center'><Spinner /></div> : <Button
													color='success'
													className='w-100 py-3'
													onClick={() => form.code && login()}>
													Verify
												</Button>)}
											</div>
										</>
									)}

									{/* BEGIN :: Social Login */}
									{!usernameInput && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												Only Admins
											</div>
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='https://dev.ahmedalhasan.com/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Ahmed Al-Hasan's Youth Development Team
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
