import React from 'react';
import { commonMenu } from '../menu';
import DashboardHeader from '../headers/DashboardHeader';

const headers = [
	{ path: commonMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DashboardHeader />,
	},
];

export default headers;
