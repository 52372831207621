import YouthServers from "@youth10313/youth-apis";
import { YouthStorage } from "./localStorage";

export function RenewToken(onChange, onError) {
    setInterval(() => {
        const expire = parseInt(localStorage.getItem('expire'));
        if (!expire) return;
        const now = new Date().getTime();
        const _private = YouthStorage.Read('private');
        if (now < expire || !_private) return;
        const login = YouthServers.Auth.Login.RenewUserToken(_private);
        login.onChange(onChange);
        login.onError(onError);
        login.onComplete(res => {
            if (res.msg !== 'TOKEN_SENT') return;
            localStorage.setItem('expire', (new Date().getTime() + res.time) - (1000 * 60 * 2));
            YouthStorage.Save('Authorization', res.token);
        })
        login.subscribe()
    }, 1000 * 60);
}