import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { YouthStorage } from "../utils/localStorage";

let user;
if (YouthStorage.Read('profile')) {
    try {
        user = JSON.parse(YouthStorage.Read('profile'));
    } catch {
        if (process.env.NODE_ENV === 'development') {
            console.log('cannot parse user from local storage');
        }
        YouthStorage.Remove('profile')
    }
}
const initialValues = {
    isAuth: Boolean(user && parseInt(localStorage.getItem('expire')) > new Date().getTime()),
    user
};

const AuthContext = createContext({
    state: initialValues,
    dispatch: () => null
});
AuthContext.displayName = "Auth Context";

export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
    const [state, setState] = useState({ ...initialValues });
    function dispatch(value) {
        setState(value)
    }
    const data = useMemo(() => ({
        state,
        dispatch
    }), [state])
    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthProvider